<template>
  <div class="change_bg">
    <Navbar></Navbar>

    <div class="container">
      <p class="site_title"> {{title}}</p>
      <div class="monetoring_description" v-if="description!='undefined'" v-html="description">
      </div>
      <div v-if="task!='undefined'">{{task}}</div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "@/components/Header/Navbar";
import Footer from "@/components/Header/Footer";

export default {
  components: {
    Footer,
    Navbar
  },
  data(){
    return{
      title:'',
      description:'',
      task:''
    }
  },
  mounted() {
    this.title=localStorage.getItem('search_title')
    this.description=localStorage.getItem('search_description')
    this.task=localStorage.getItem('search_task')
  }
}
</script>